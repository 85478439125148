/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { FaArrowRight, FaClock, FaUniversity } from 'react-icons/fa';
import { motion } from 'framer-motion';
import moment from 'moment';

export type TClassCard = {
  name: string;
  speakerName: string;
  speakerImage?: string;
  classStartDateTime: Date;
  classEndDateTime: Date;
  meetupPageLink?: string;
  description?: string;
};

export default function ClassCard(props: TClassCard) {
  const { name } = props;
  const duration = moment.duration(moment(props.classEndDateTime).diff(props.classStartDateTime));
  const durationStr = duration.humanize();
  return (
    <div className="card">
      <div className="card-content">
        <div className="media">
          <div className="media-left">
            <figure className="image is-48x48">
              <img src={props.speakerImage || 'https://bulma.io/images/placeholders/96x96.png'} alt="Speaker image" />
            </figure>
          </div>
          <div className="media-content">
            <p className="title is-4"> {name}</p>
            <p className="subtitle is-6">
              By <a href="">@{props.speakerName}</a>
            </p>
          </div>
        </div>

        <div className="content">
          <span style={{ maxHeight: '40px', overflow: 'auto' }}>
            <div className="flex" css={css`display:grid; grid-template-columns: repeat(3, 1fr); gap: 30px 20px;`}>
              <div>
                <FaUniversity />
                Starts In: <b>{props.classStartDateTime && moment(props.classStartDateTime).fromNow()}</b>
              </div>
              <div>
                <FaClock />
                Class Duration: <b>{props.classEndDateTime && props.classStartDateTime && durationStr}</b>
              </div>
              {/* <ul css={css`list-style-type: 'none'`}>
                <li>
                 
                </li>
                <li>
                  Class Duration: <b>{props.classEndDateTime && props.classStartDateTime && durationStr}</b>
                </li>
              </ul> */}
            </div>
          </span>
          <div />
        </div>
        <p>{props.description}</p>
        <motion.div
          whileHover={{ scale: 1.1 }}
          css={css`font-size: 1.3rem; font-weight: 600; letter-spacing: 1px;`}
          className="is-pulled-right mb-4"
        >
          {props.meetupPageLink && (
            <a href={props.meetupPageLink} css={css`display: flex; align-items: center;`}>
              Go To Event page
              <FaArrowRight css={css`margin-left: 0.5rem;`} />
            </a>
          )}
        </motion.div>
      </div>
    </div>
  );
}
