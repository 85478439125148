/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { css, jsx } from '@emotion/core';
import { FaCircle, FaTimes, FaPlayCircle, FaStopCircle, FaCompress, FaExpand } from 'react-icons/fa';
import { toast } from 'react-toastify';

import useStore from 'globalstate';
import { useEventStore } from 'globalstate/Events';
import { TEventData } from 'types';
import Error from 'components/Courseware/Views/ErrorView';
import { USER_GET_CLASS_URL, ADMIN_START_CLASS_URL, CLASS_EVENT_WS_URI } from 'constants/app';
import { ERROR_PALE, PRIMARY_BACKGROUND_PALEST } from 'constants/colors';
import { getCurrentPath } from 'utils/url';
import fetch from 'utils/fetch';
import { getDateTime } from 'utils/datetime-handler';
import { captureException } from '@sentry/minimal';

import ZoomLive from './ZoomFramer';

export type TClassData = {
  id: string;
  class_name: string;
  class_description: string;
  meeting_link: string;
  schedule_date_time: string;
  status: number;
  created_on: string;
  updated_on: string;
  admin_meeting_link: string;
  class_page_link: string;
  course_id: string;
};

type TZoomProps = {
  id: number;
  password: string;
  signature: string;
};

interface IProps {
  classId: string;
  meetupId?: string;
}

export default function (props: IProps) {
  const [isLive, setisLive] = useState<boolean>(false);
  const [zoomIsBig, setzoomIsBig] = useState<boolean>(true);
  const [zoomProps, setzoomProps] = useState<TZoomProps>();
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const [floatLiveBanner, setFloatLiveBanner] = useState<boolean>(true);
  const [classData, setClassData] = useState<TClassData>();
  const [goLiveStatus, setGoLiveStatus] = useState<boolean>(false);
  const [liveEventMessage, setliveEventMessage] = useState<string>();
  const [liveEventPortal, setLiveEventPortal] = useState<Element>();
  const [liveWS, setLiveWS] = useState<WebSocket>();
  const [, eventActions] = useEventStore();
  const [State] = useStore();

  const { token, isAuthenticated } = State;
  const { classId } = props;

  /**
   * Opens Zoom URI and web socket connection to class
   *
   */
  const openZoomAndWS = () => {
    setisLive(true);

    if (goLiveStatus) {
      let zoomURI;

      if (State.isTA) {
        zoomURI = classData?.admin_meeting_link;
        setAdminMeetingStart(zoomURI);
      } else {
        // zoomURI = classData?.meeting_link;
        // window.open(zoomURI, '_blank');
      }
    }

    subscribeToLiveWS(token);
  };

  const setAdminMeetingStart = async (zoomURI: string | undefined) => {
    if (classData?.id) {
      const params = {
        method: 'POST',
        body: JSON.stringify({
          class_id: classData?.id,
          event: 1,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      };

      fetch(ADMIN_START_CLASS_URL, { credentials: 'header', ...params })
        .then(async response => {
          if (response.status === 200) {
            //found the live class for the url or class id lets show him the Timer
            const respJSON = await response.json();
            if (respJSON['success'])
              //go ahead start the class Zoom Link
              window.open(zoomURI, '_blank');
          } else {
            //unable to start the class
            toast.error('Unable to start class, Please retry after some time.', { position: 'top-center' });
          }
        })
        .catch(() => {
          toast.error('No internet connection found. App is running in offline mode.', { position: 'top-center' });
        });
    }
  };

  const propogateEvent = (eventData: TEventData) => {
    eventActions.setEvent(eventData);
  };

  const subscribeToLiveWS = (jwt: string) => {
    const courseId = localStorage.getItem('selectedCourse');
    const ws: WebSocket = new WebSocket(
      `${CLASS_EVENT_WS_URI}?border-patrol-jwt=${jwt}&receiverId=${classData?.id}&course_id=${
        courseId ? JSON.parse(courseId).id : 1
      }`
    );
    setIsConnecting(true);

    if (!ws) {
      toast.error('Unable to connect to events', { position: 'top-center' });

      return;
    }
    setLiveWS(ws);

    ws.onopen = () => {
      setisLive(true);
    };

    ws.onmessage = event => {
      if (!event.data) return;
      if (event.data == 'Subscribed') return toast.success('Connected to class');

      const eventData: TEventData = JSON.parse(event.data);
      propogateEvent(eventData);
    };

    ws.onerror = error => {
      captureException(error);
      ws.close();
    };

    ws.onclose = () => {
      unsubscribeFromLive();
    };
  };

  const unsubscribeFromLive = () => {
    setIsConnecting(false);
    setisLive(false);
    if (liveWS) liveWS.close();
  };

  const checkClassDetails = () => {
    const time = getDateTime(classData?.schedule_date_time);

    if (classData?.status === 0) {
      // class is scheduled but meeting link not generated
      const message = 'Class is scheduled on ' + time + ' Awaiting meeting link';
      setliveEventMessage(message);
      setGoLiveStatus(false);
    } else if (classData?.status === 1) {
      // class is scheduled meeting link generated and will happend in future
      setliveEventMessage('Your lecture is live! Join now');

      setGoLiveStatus(true);
    } else if (classData?.status === 2) {
      // class is scheduled meeting link generated and will happend in future
      setliveEventMessage('Your class will be live on ' + time);

      setGoLiveStatus(true);
    } else if (classData?.status === 3) {
      // class is fully completed and user needs to replay the previous events
      setliveEventMessage('Replay class');
      setGoLiveStatus(true);
    } else if (classData?.status === 4) {
      // class is partially completed and there can be future classes to complete the class_page_link chapter
      setliveEventMessage('Replay class');
      setGoLiveStatus(true);
    } else if (classData?.status === 5) {
      // class is cancelled and will not be held :(
      setliveEventMessage('Class is cancelled');
      setGoLiveStatus(false);
    } else {
      // something else provide use a default
      setliveEventMessage('Your lecture is live! Join now');
      // setliveEventMessage('Unavailable');
      setGoLiveStatus(true);
      // setGoLiveStatus(false);
    }
  };

  const getClassDetails = async (currentPathname: string, class_id?: string) => {
    // fetch class details first if class id is present if not then use urlParameter to find class with only status 0
    const queryParams = class_id != undefined ? [['class_id', class_id]] : [['class_url', currentPathname]];
    const params = new URLSearchParams(queryParams).toString();

    fetch(USER_GET_CLASS_URL + '?' + params, { credentials: 'header' })
      .then(async response => {
        if (response.status === 200) {
          //found the live class for the url or class id lets show him the Timer
          const respJSON = await response.json();
          const classData = respJSON['courseClass'][0];
          setClassData(classData);
          setzoomProps(classData.meeting_meta.zoomDetails);
          checkClassDetails();
          if (!classData.meeting_meta) setliveEventMessage('Problem getting meeting details. Please refresh the page.');
        } else {
          // no live class found for it let's ask user to wait till its created
          setClassData(undefined);
          setliveEventMessage('This class has not been scheduled yet. Do check again soon!');
        }
      })
      .catch(() => {
        // TO-DO: handle
        return null;
      });
  };

  const toggleZoomModal = () => {
    setzoomIsBig(!zoomIsBig);
  };

  useEffect(() => {
    const currentPathname = getCurrentPath();
    getClassDetails(currentPathname, classId);

    const portal = document.getElementById('event-portal') as Element;
    setLiveEventPortal(portal);
  }, []);

  useEffect(() => {
    if (isAuthenticated) checkClassDetails();
  }, [classData, goLiveStatus]);

  if (!classId) return null;
  if (!isAuthenticated) return <Error preset="login" />;
  const GoLiveEventNode = (
    <div
      css={css`
        width: 100vw;
        padding: 1rem 2rem;
        background-color: ${PRIMARY_BACKGROUND_PALEST};
        display: flex;
        align-items: center;
        justify-content: space-between;
      `}
    >
      {liveEventMessage}
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <button
          disabled={isConnecting || !goLiveStatus}
          className={`button is-primary ${isConnecting ? 'is-loading' : ''}`}
          onClick={isConnecting || !goLiveStatus ? undefined : openZoomAndWS}
          css={css`margin-left: 1rem;`}
        >
          Go Live <FaPlayCircle css={css`margin-left: 5px;`} />
        </button>
        {floatLiveBanner && (
          <button
            className="button is-text"
            onClick={() => {
              setFloatLiveBanner(false);
            }}
            css={css`margin-left: 1rem;`}
          >
            <FaTimes />
          </button>
        )}
      </div>
    </div>
  );

  const LiveEventNode = (
    <div
      css={css`
        width: 100vw;
        padding: 0.5rem 2rem;
        background-color: ${PRIMARY_BACKGROUND_PALEST};
        display: flex;
        align-items: center;
        justify-content: space-between;
      `}
    >
      <div css={css`display: flex; align-items: center;`}>
        You are live
        <FaCircle
          css={css`
            margin-left: 5px;
            color: ${ERROR_PALE};
            animation: blinker 1s linear infinite;
            @keyframes blinker {
              50% {
                opacity: 0;
              }
            }
          `}
        />
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <button className="button" onClick={toggleZoomModal} css={css`margin-left: 1rem;`}>
          {zoomIsBig ? 'Minimize' : 'Maximize'}
          {zoomIsBig ? <FaCompress css={css`margin-left: 5px;`} /> : <FaExpand css={css`margin-left: 5px;`} />}
        </button>
        <button className="button" onClick={unsubscribeFromLive} css={css`margin-left: 1rem;`}>
          Disconnect <FaStopCircle css={css`margin-left: 5px;`} />
        </button>
      </div>
    </div>
  );

  const toRender = isLive ? LiveEventNode : GoLiveEventNode;

  if (!isAuthenticated) return null;

  return (
    <React.Fragment>
      {(isLive || floatLiveBanner) && (
        <div
          css={css`
            position: fixed;
            top: 0;
            left: 0;
            z-index: 35;
          `}
        >
          {toRender}
        </div>
      )}
      {liveEventPortal ? ReactDOM.createPortal(toRender, liveEventPortal) : null}
      {zoomProps && (
        <ZoomLive
          isOpen={isLive}
          isBig={zoomIsBig}
          toggleSize={toggleZoomModal}
          closeMeeting={unsubscribeFromLive}
          zoomProps={zoomProps}
        />
      )}
    </React.Fragment>
  );
}
