/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { Redirect, useLocation } from 'react-router-dom';

// import Spinner from 'fragments/Spinner';
import { useEventStore } from 'globalstate/Events';
import useStore from 'globalstate';

export default function () {
  const location = useLocation();
  const [redirectTo, setredirectTo] = useState<string>('');

  const [, eventActions] = useEventStore();
  const [, Actions] = useStore();

  useEffect(() => {
    if (!location.search) return;

    const searchParams = new URLSearchParams(location.search);
    const isNavCatcher = searchParams.get('navCatcher');
    if (!isNavCatcher) return;

    const navDataRaw = searchParams.get('data');
    if (!navDataRaw) return;

    const navData = JSON.parse(navDataRaw);

    eventActions.setEvent({ to: navData.id, data: navData, navCatched: true });
    Actions.setState({ sidebarTab: 'note' });

    setredirectTo(location.pathname);
  }, [location]);

  if (redirectTo) return <Redirect to={redirectTo} />;

  // if (loading)
  //   return (
  //     <div
  //       css={css`height: 100vh; width: 100vw; position: fixed; background-color: rgba(0, 0, 0, 0.3);
  //         display: flex; align-items: center; justify-content: center; z-index: 6;`}
  //     >
  //       <Spinner />
  //     </div>
  //   );
  return null;
}
