/* @jsx jsx */
import React, { useState } from 'react';
import { css, jsx } from '@emotion/core';
import { useLocation, Link } from 'react-router-dom';
import { FaUser, FaSignInAlt, FaCaretDown } from 'react-icons/fa';
// import { useScrollPosition } from '@n8tb1t/use-scroll-position';
// import { motion } from 'framer-motion';
import { createHtmlPortalNode, InPortal, OutPortal } from 'react-reverse-portal';

import {
  APPLICATION_PRODUCTS_URL,
  HELP_URL,
  USER_APPLICATION_URL,
  USER_PROFILE_URL,
  USER_CERTIFICATES_URL,
  getAuthRefreshURL,
  getLogoutURL,
  getLoginURL,
  NOTES_PATH,
} from 'constants/app';
import { TOP_BAR_HEIGHT } from 'constants/measures';
import useStore from 'globalstate';
import { cleanURL } from 'utils/url';
import HeaderNotifications from 'fragments/HeaderNotifications';

const loginButtonStyle = {
  marginRight: '10px',
};

// interface AuthCookie {
//   aud: string,
//   dashboard: string,
//   exp: number,
//   full: string,
//   iat: number,
//   iss: string,
//   roles: string,
//   sub: string,
//   userId: number,
//   username: string,
// }

export default function TopBar() {
  const [isMenuOpen, toggleMenu] = useState(false); // for mobile
  // const [isFixed, setIsFixed] = useState(false); // desktop
  // const [isFixedOpen, setIsFixedOpen] = useState(false); // desktop

  const [State] = useStore();
  const location = useLocation();

  const { isAuthenticated, userDetails } = State;
  const currentURL = cleanURL(window.location.href);

  // const toggleBar = (show: boolean) => {
  //   Actions.setState({ isShowingFloatingTopbar: show });
  //   // setIsFixed(show);
  // }

  // useEffect(() => {
  //   // toggleMenuShower(!State.isShowingFloatingTopbar);
  //   setIsFixed(State.isShowingFloatingTopbar);
  // }, [State.isShowingFloatingTopbar]);

  // useScrollPosition(({ prevPos, currPos }) => {
  //   const currentY = -currPos.y;
  //   const threshold = TOP_BAR_HEIGHT * 2;
  //   if (currentY < threshold) {
  //     if (!isFixed) return;
  //     setIsFixedOpen(false);
  //     return toggleBar(false);
  //   }
  //   if (currentY > threshold) {
  //     if (isFixed) return;
  //     toggleBar(true);
  //   }
  // }, undefined, undefined, undefined, 1000);

  const portalNode = React.useMemo(() => createHtmlPortalNode(), []);

  if (location.pathname.split('/').length > 3) return null;

  const Bars = (
    <React.Fragment>
      <div className="container" css={css`height: ${TOP_BAR_HEIGHT}px;`}>
        <nav className="navbar is-transparent has-shadow" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/">
              <img src="logo.svg" css={css`height: 22px;`} />
            </Link>
            <a
              role="button"
              className={`navbar-burger burger${isMenuOpen ? ' is-active' : ''}`}
              aria-label="menu"
              aria-expanded="false"
              data-target="topBarNav"
              onClick={() => toggleMenu(!isMenuOpen)}
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </a>
          </div>

          <div id="topBarNav" className={`navbar-menu${isMenuOpen ? ' is-active' : ''}`}>
            <div className="navbar-end">
              {/* <div css={css`display: flex; align-items: center; justify-content: center;`}>
                {loggedInIndicator && <FaRegBell />}
              </div> */}
              {isAuthenticated && <HeaderNotifications />}
              <a className="navbar-item" href={HELP_URL}>
                Contact us
              </a>

              {isAuthenticated ? (
                <div>
                  <div className="navbar-item has-dropdown is-hoverable">
                    <div className="navbar-link is-arrowless">
                      <button className="button is-outlined is-primary" css={css`min-width: 10rem;`}>
                        <FaUser style={loginButtonStyle} />
                        {userDetails.username}
                        <FaCaretDown css={css`margin-left: 5px;`} />
                      </button>
                    </div>
                    <div className="navbar-dropdown">
                      <a className="navbar-item" href={USER_APPLICATION_URL}>
                        My Applications
                      </a>
                      <a className="navbar-item" href={USER_CERTIFICATES_URL}>
                        My Certificates
                      </a>
                      <a className="navbar-item" href={USER_PROFILE_URL}>
                        My Profile
                      </a>
                      <a className="navbar-item" href={NOTES_PATH}>
                        My Notes
                      </a>
                      <a className="navbar-item" href={APPLICATION_PRODUCTS_URL}>
                        Apply for Programs
                      </a>
                      <a className="navbar-item" href={getLogoutURL(currentURL)}>
                        Logout
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="navbar-item">
                  <div className="buttons">
                    <a className="button is-primary" href={getLoginURL(currentURL)}>
                      <FaSignInAlt style={loginButtonStyle} />
                      Login
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </nav>
      </div>
      <div id="breadcrumb-bar-portal" />
    </React.Fragment>
  );

  return (
    <div css={css`height: ${TOP_BAR_HEIGHT}px;`}>
      <InPortal node={portalNode}>{Bars}</InPortal>
      {/* {
        isFixed ? (
          <motion.div
            initial="hidden"
            animate={isFixed ? "visible" : "hidden"}
            variants={{
              hidden: { translateY: '-100vh' },
              visible: { translateY: 0 },
            }}
            css={css`position: fixed; top: 0; width: 100%; background-color: ${PRIMARY_BACKGROUND}; z-index: 2;`}
          >
            {
              isFixedOpen ? <OutPortal node={portalNode} /> : (
                <div
                  onClick={() => { setIsFixedOpen(true); }}
                  css={css`cursor: pointer; position: absolute; left 49%; top: -1rem;
                    border-radius: 1rem; height: 2rem; width: 2rem; padding-top: 1rem;
                    display: flex; align-items: center; justify-content: center;
                    color: white; background-color: ${DARK_ICON}; font-weight: 600;
                  `}
                >
                  <FaChevronDown size="1rem" />
                </div>
              )
            }
          </motion.div>
        ) : (
          <div css={css`z-index: 5; position: relative;`}>
            <OutPortal node={portalNode} />
          </div>
        ) 
      } */}
      {/* <div css={css`z-index: 5; position: fixed; top: 0; width: 100%; `}> */}
      <div css={css`z-index: 5; width: 100%; `}>
        <OutPortal node={portalNode} />
      </div>
    </div>
  );
}
