import { useEffect } from 'react';
import { History, LocationState } from 'history';
import { withRouter } from 'react-router-dom';

type THistory = History<LocationState>;

const ScrollToTop = ({ history }: { history: THistory }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });

    return () => unlisten();
  }, []);

  return null;
};

export default withRouter(ScrollToTop);
