import React from 'react';
import useGlobalHook, { Store } from 'use-global-hook';

// state
type State = { [key: string]: any };
const initialState: {} = {};

// actions
type Actions = {
  setState: ({}) => void;
};

const setState = (store: Store<State, Actions>, data: {}) => {
  const state = { ...store.state, ...data };
  store.setState(state);
};

const Actions = { setState };

// store
export default useGlobalHook<State, Actions>(React, initialState, Actions);
