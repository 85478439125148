export const PRIMARY_APP = '#324bd9';
export const PRIMARY_BACKGROUND = '#FFFFFF';
export const PRIMARY_BACKGROUND_OPACITY = 'rgba(50, 75, 217, 0.3)';
export const PRIMARY_BACKGROUND_THICK = '#dee0f6';
export const PRIMARY_BACKGROUND_COMPLEMENT = '#eff0fb';
export const PRIMARY_BACKGROUND_DARK = '#233599';
export const PRIMARY_BACKGROUND_PALEST = '#dee0f6';
export const PRIMARY_BACKGROUND_PALER = '#b8bced';
export const PALE_SEPARATOR = '#9c9c9c';
export const PALER_SEPARATOR = '#d4d4d4';
export const PALEST_SEPARATOR = '#f5f5f5';
export const DARK_TEXT = '#5c5c5c';
export const DARK_ICON = '#4a4a4a';
export const DEFAULT_TEXT = '#1e2029';
export const ERROR = '#fc0000';
export const ERROR_PALE = '#ff3838';
export const SUCCESS = '#009000';
export const SUCCESS_PALE = '#458745';
export const SUCCESS_PALEST = '#e6f7ec';
