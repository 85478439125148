/* @jsx jsx */
import React, { useState, useEffect } from 'react';
import fetch from 'utils/fetch';
import { css, jsx } from '@emotion/core';
import moment from 'moment';
import { NOTIFICATION_API_GET_MESSAGES } from 'constants/app';
import { toast } from 'react-toastify';

export default function AllUserNotifications() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allNotifications, setAllNotifications] = useState<any[]>([]);

  const fetchOldNotifications = async () => {
    const params = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    };
    setIsLoading(true);
    const res = await fetch(NOTIFICATION_API_GET_MESSAGES + '?messageType=2', { credentials: 'header', ...params });
    res.json().then(result => {
      setAllNotifications(result.data);
      setIsLoading(false);
    });
  };

  const loadMore = async () => {
    const params = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    };
    const lastMsgId = allNotifications[0]._id.$oid;
    setIsLoading(true);
    const res = await fetch(NOTIFICATION_API_GET_MESSAGES + '?lastMessageId=' + lastMsgId + '&messageType=2', {
      credentials: 'header',
      ...params,
    });
    res.json().then(result => {
      if (result.data.length > 0) {
        const temp = allNotifications;
        temp.splice(0, 0, result.data);
        setAllNotifications(temp);
      } else {
        toast.info('No More Notifications!');
      }
      setIsLoading(false);
    });
  };
  useEffect(() => {
    fetchOldNotifications();
  }, []);

  return (
    <div className="columns is-gapless">
      <div className="is-three-fifths is-offset-one-fifth column mt-6">
        <div className="title pt-6">Notifications({allNotifications.length})</div>
        <div className="box" style={{ maxHeight: '500px', overflow: 'auto' }}>
          {allNotifications.map(item => {
            return (
              <div
                className="is-clickable"
                onClick={() => {
                  window.location.href = item.link;
                }}
              >
                <p className=" is-size-5">{item.text}</p>
                <span className="has-text-weight-light is-size-7">{moment(item.created_at).fromNow()}</span>
                <hr className="dropdown-divider" />
              </div>
            );
          })}
        </div>
        <div style={{ left: '50%', position: 'fixed', bottom: '50px' }}>
          <button className={isLoading ? 'is-loading button is-primary' : 'button is-primary'} onClick={loadMore}>
            Load More
          </button>
        </div>
      </div>
    </div>
  );
}
