/** @jsx jsx */
import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import { FaExpand, FaTimes } from 'react-icons/fa';
import Draggable from 'react-draggable';
import { css, jsx } from '@emotion/core';

import useStore from 'globalstate';
import { PALER_SEPARATOR, PALE_SEPARATOR } from 'constants/colors';
import { TOP_BAR_HEIGHT } from 'constants/measures';
import { ZOOM_FCS_URL } from 'constants/app';

interface IProps {
  closeMeeting: () => void;
  toggleSize: () => void;
  isBig: boolean;
  isOpen: boolean;
  zoomProps: {
    id: number;
    signature: string;
    password: string;
  };
}

export default function ProcessModal(props: IProps) {
  const { closeMeeting, isBig, isOpen, toggleSize, zoomProps } = props;

  const zoomRef = useRef<HTMLIFrameElement | null>(null);
  const [State] = useStore();

  const zoomframe = useCallback(node => {
    if (!node) return;
    zoomRef.current = node;

    const postMessageReceived = (e: { data: { type: string; height: number; top: number }; origin: string }) => {
      if (e.data.type === 'getZoomProps') {
        const { userDetails } = State;

        node.contentWindow?.postMessage(
          {
            type: 'zoomProps',
            zoomProps: { userName: userDetails.name, userEmail: userDetails.email, ...zoomProps },
          },
          '*'
        );
      }

      if (e.data.type === 'zoomExited') closeMeeting();
    };

    window.addEventListener('message', postMessageReceived);
    node.contentWindow?.postMessage({ type: 'readyToZoom' }, '*');

    return () => {
      window.removeEventListener('message', postMessageReceived);
    };
  }, []);

  useEffect(() => {
    if (!zoomRef.current) return;

    zoomRef.current.contentWindow?.postMessage({ type: 'frameSize', isSmall: !isBig }, '*');
  }, [zoomRef.current, isBig]);

  const ZoomIFrame = useMemo(
    () => (
      <iframe
        allow="microphone; camera; fullscreen; display-capture"
        allowFullScreen
        ref={zoomframe}
        src={ZOOM_FCS_URL}
        css={css`height: 100%; width: 100%;`}
      />
    ),
    []
  );

  if (!isOpen) return null;

  return (
    <React.Fragment>
      <Draggable position={isBig ? { x: 0, y: 0 } : undefined}>
        <div
          css={css`position: fixed; z-index: 30;
            ${
              isBig
                ? `height: calc(100vh - ${TOP_BAR_HEIGHT}px); top: ${TOP_BAR_HEIGHT}px; left: 0; width: 100vw;`
                : `left: 0; bottom: 0;
                    height: 30vh; width: 50vh;
                    z-index: 50; cursor: move;
                    background-color: ${PALER_SEPARATOR};
                  `
            }
          `}
        >
          {!isBig && (
            <div
              css={css`display: flex; align-items: center; justify-content: space-between;
                width: 100%; padding: 0.75rem 1rem;
                border-top-left-radius: 1rem; border-top-right-radius: 1rem;
            `}
            >
              <span css={css`1rem; color: ${PALE_SEPARATOR};`}>Drag to move</span>
              <div>
                <FaExpand onClick={toggleSize} css={css`cursor: pointer; margin-right: 1rem;`} />
                <FaTimes onClick={closeMeeting} css={css`cursor: pointer;`} />
              </div>
            </div>
          )}
          {ZoomIFrame}
        </div>
      </Draggable>
    </React.Fragment>
  );
}
