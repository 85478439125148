/** @jsx jsx */
import Error from 'components/Courseware/Views/ErrorView';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LiveView from '.';
import { css, jsx } from '@emotion/core';
import { USER_GET_MEETUP_CLASS_URL } from 'constants/app';
import fetch from 'utils/fetch';
import useStore from 'globalstate';
import ClassCard from 'components/Courseware/Views/LiveView/ClassCard';
import { ClassDetails, IClassDetailResult, MeetupDetails } from './types';

function ClassView() {
  const { meetupId, classId } = useParams<{ meetupId: string; classId: string }>();
  const [classData, setClassData] = useState<ClassDetails>();
  const [meetupData] = useState<MeetupDetails>();
  const [error, setError] = useState<string>();
  const [State] = useStore();

  const { isAuthenticated } = State;

  const getClassDetailsForMeetup = async (classId: string, meetupId: string) => {
    // fetch class details first if class id is present if not then use urlParameter to find class with only status 0
    const queryParams = [
      ['class_id', classId],
      ['meetup_id', meetupId],
    ];
    const params = new URLSearchParams(queryParams).toString();

    fetch(USER_GET_MEETUP_CLASS_URL + '?' + params, { credentials: 'header' })
      .then(async response => {
        if (response.status === 200) {
          //found the live class for the url or class id lets show him the Timer
          const respJSON: { data: IClassDetailResult } = await response.json();
          const classData = respJSON.data.classDetails;
          if (!classData) return setError('No such class scheduled.');
          setClassData(classData);

          if (!classData.meeting_meta) setError('Problem getting meeting details. Please refresh the page.');
        } else {
          // no live class found for it let's ask user to wait till its created
          setClassData(undefined);
          const error = (await response.json()).error || 'No such class , Please contact Admin';
          setError(error);
        }
      })
      .catch(() => {
        // TO-DO: handle
        return null;
      });
  };
  useEffect(() => {
    getClassDetailsForMeetup(classId, meetupId);
  }, []);
  if (!isAuthenticated) return <Error preset="login" />;
  if (!classId) return <Error preset="broken" />;
  if (error) return <Error text={error} emoji="( ˘︹˘ )" />;
  if (!meetupId || !classId) return <Error preset="broken" />;

  return (
    <div className=" flex w-1/2">
      <div css={css`margin-top:4rem;`}>
        {classData && (
          <div css={css`display:flex;place-content:center;`}>
            <div css={css`max-width:50%;justify`}>
              {/* <CardLong {...(classData as unknown as IParsedCourseTimetable)} /> */}
              <ClassCard
                name={classData.class_name}
                speakerImage={classData.meeting_meta.speakerImage}
                speakerName={classData.meeting_meta.speakerName || 'Univ.ai Team'}
                classStartDateTime={classData.schedule_date_time}
                classEndDateTime={classData.class_end_date_time}
                meetupPageLink={meetupData?.page_url}
                description={classData.class_description || meetupData?.description}
              />
            </div>
          </div>
        )}
      </div>
      <LiveView classId={classId} meetupId={meetupId} />
    </div>
  );
}

export default ClassView;
