/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import { FaChevronLeft } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';

import useStore from 'globalstate';
import { PRIMARY_APP } from 'constants/colors';
import { getLoginURL, getAuthRefreshURL, HELP_URL } from 'constants/app';

type TPresetKey = 'broken' | 'pay2win' | 'nothing' | 'login';
export type TPresetValue = {
  emotext?: string | JSX.Element;
  emoji?: string;
  text?: string | JSX.Element;
  extrablock?: JSX.Element;
  preset?: TPresetKey;
  doBack?: boolean;
  embed?: boolean;
};
type TPreset = { [key in TPresetKey]: TPresetValue };

function getPresets(params?: { [key: string]: string | boolean }): TPreset {
  return {
    broken: {
      emotext: 'Something is not right',
      emoji: '(╯°□°)╯︵ ┻━┻',
      text: (
        <p>
          Please let us know if this problem persists via our <a href={HELP_URL}>Help page</a>
        </p>
      ),
    },
    pay2win: {
      emotext: 'Access restricted',
      emoji: 'ヾ(⌐■_■)ノ♪',
      text: (
        <div>
          This content is restricted. Ensure you&apos;re logged in.
          <p>To get access to our flagship programs, check out our programs page.</p>
        </div>
      ),
      extrablock: (
        <div>
          {params && params.isLoggedIn && (
            <div css={css`margin-top: 1.33rem;`}>
              <a href={getAuthRefreshURL(window.location.href)}>
                If you are enrolled and do not have access, refresh tokens
              </a>
            </div>
          )}
          <div css={css`display: flex; align-items: center; margin-top: 1.33rem;`}>
            {params && !params.isLoggedIn && (
              <a className="button is-primary is-inverted" href={getLoginURL(window.location.href)}>
                Login
              </a>
            )}
            <Link className="button" to="/">
              Our Programs
            </Link>
          </div>
        </div>
      ),
    },
    nothing: {
      emotext: 'No results found',
      emoji: '（>﹏< )彡',
    },
    login: {
      emoji: '┃(・_┃',
      emotext: 'Login to see things here',
      text: 'Content on this page is based on what you do. Log in to do more!',
      doBack: false,
      extrablock: (
        <div css={css`margin-top: 3.3rem; display: flex; justify-content: center;`}>
          <a className="button is-primary is-inverted" href={getLoginURL(window.location.href)}>
            Login / Register
          </a>
        </div>
      ),
    },
  };
}

export default function (props: TPresetValue) {
  const { preset, embed = false } = props;
  let { emotext, emoji, text, extrablock, doBack = false } = props;

  const history = useHistory();
  const [State] = useStore();

  const { isAuthenticated } = State;

  if (preset) {
    const maybePreset = getPresets({ isLoggedIn: isAuthenticated })[preset];

    if (maybePreset) {
      if (!emotext && maybePreset.emotext !== undefined) emotext = maybePreset.emotext;
      if (maybePreset.emoji !== undefined) emoji = maybePreset.emoji;
      if (!text && maybePreset.text !== undefined) text = maybePreset.text;
      if (maybePreset.extrablock !== undefined) extrablock = maybePreset.extrablock;
      if (maybePreset.doBack !== undefined) doBack = maybePreset.doBack;
    }
  }

  return (
    <div
      className="content"
      css={css`width: 100vw; padding: 1rem;
        ${embed ? 'margin: 6%; width: 66%;' : 'height: 70vh;'}
        @media (max-width: 768px) {
          margin: 0; width: 100%; font-size: 1.33rem;
        };
    `}
    >
      <div
        css={css`font-family: Space Grotesk;
          max-width: 768px;
          ${embed ? '' : 'position: absolute; left: 50%; top: 50%; transform: translate(-50%, -60%);'}
      `}
      >
        <h4
          css={css`text-align: right;
            @media (min-width: 768px) {
              ${embed ? '' : 'margin-left: 15rem;'}
              padding-bottom: 1rem;
            };
        `}
        >
          {emoji}
        </h4>
        <div
          css={css`color: ${PRIMARY_APP}; font-size: 1rem; letter-spacing: 1.1px; font-weight: 500; text-transform: uppercase;
            margin-bottom: 1rem;`}
        >
          {emotext}
        </div>
        <p css={css`font-weight: 300; font-size: 1.33rem;`}>{text}</p>
        {extrablock}
        {doBack && (
          <div css={css`padding-top: 3.33vh; display: flex; justify-content: center;`}>
            <button onClick={history.goBack} className="button is-white">
              <FaChevronLeft />
              <span css={css`margin-left: 0.5rem;`}>Go Back</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
