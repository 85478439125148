import React, { useState, useEffect } from 'react';
import {
  FaGraduationCap,
  FaInfo,
  FaInfoCircle,
  FaPencilAlt,
  FaPencilRuler,
  FaRegBell,
  FaRegEdit,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { captureException } from '@sentry/minimal';

import { LIVE_EVENT_WS_URI, NOTIFICATION_API_GET_MESSAGES, NOTIFICATION_PATH } from 'constants/app';
import useStore from 'globalstate';
import fetch from 'utils/fetch';
import * as Sentry from '@sentry/browser';

export default function HeaderNotifications() {
  const [error, seterror] = useState<string>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNotificationSoundPlaying, setIsNotificationSoundPlaying] = useState<boolean>(false);
  const [oldNotifications, setOldNotifications] = useState<TNotification[]>([]);
  const [newMessageCount, setNewMessageCount] = useState<number>(0);

  const [State] = useStore();
  const { token } = State;

  type TNotification = {
    _id: {
      $oid: string;
    };
    receiverId: string;
    messageType: number;
    senderId: string;
    link: string;
    clicked: boolean;
    viewed: boolean;
    clickTime: string;
    viewTime: string;
    text: string;
    isRealTime: boolean;
    iconType?: number;
    createdAt: {
      $date: number;
    };
    updatedAt: {
      $date: number;
    };
  };

  const playNotificationSound = () => {
    const playSound = () => {
      const audio = new Audio('http://content.dev.univ.ai/static/notification.mp3');
      audio.play();
    };

    if (!isNotificationSoundPlaying) {
      playSound();
      setIsNotificationSoundPlaying(true);
    }
    setTimeout(() => {
      setIsNotificationSoundPlaying(false);
    }, 5000);
  };

  const subscribeToLiveWS = () => {
    const ws: WebSocket = new WebSocket(`${LIVE_EVENT_WS_URI}?border-patrol-jwt=${token}`);
    setIsConnected(true);

    if (!ws) {
      // try again after a while
      setTimeout(() => {
        subscribeToLiveWS();
      }, 10000);

      return;
    }

    ws.onopen = () => {
      setIsConnected(true);
    };

    ws.onmessage = event => {
      if (!event.data) return;

      if (event.data != 'Subscribed')
        try {
          const eventData: TNotification = JSON.parse(event.data);

          playNotificationSound();
          oldNotifications.splice(0, 0, eventData);
          setNewMessageCount(oc => (oc += 1));
        } catch (err) {
          captureException(err);
        }
    };

    ws.onerror = error => {
      seterror('Unable to fetch notifications.');
      captureException(error);
      setIsLoading(false);
      ws.close();
    };
  };

  const fetchOldNotifications = async () => {
    const params = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    };
    setIsLoading(true);

    try {
      const res = await fetch(NOTIFICATION_API_GET_MESSAGES, { credentials: 'header', ...params });
      res.json().then(result => {
        setOldNotifications(result.data);
      });
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const changeLastReadTime = () => {
    setNewMessageCount(0);
  };
  useEffect(() => {
    fetchOldNotifications();
    if (token) subscribeToLiveWS();
  }, []);

  const getIconForEntry = (type?: number) => {
    const classProperty = 'icon is-large has-text-weight-light mr-1';

    switch (type) {
      case 1:
        return <FaGraduationCap className={classProperty} />;
      case 2:
        return <FaInfo className={classProperty} />;
      case 3:
        return <FaPencilRuler className={classProperty} />;
      case 4:
        return <FaPencilAlt className={classProperty} />;
      case 5:
        return <FaRegEdit className={classProperty} />;
      default:
        return <FaInfoCircle className={classProperty} />;
    }
  };

  return (
    <div className={isOpen ? 'dropdown is-active' : 'dropdown'}>
      <div
        className="dropdown-trigger is-flex is-clickable is-align-self-center"
        onClick={() => {
          changeLastReadTime();
          setIsOpen(!isOpen);
        }}
      >
        <div>
          <FaRegBell className="mr-2 mt-2" />
          {newMessageCount > 0 && (
            <span
              className="badge badge-light is-size-7"
              style={{
                position: 'relative',
                top: '-10px',
                left: '-15px',
                border: '0px solid black',
                borderRadius: '30%',
                width: '44px',
                padding: '0px',
                background: 'red',
                fontWeight: 900,
                color: 'white',
              }}
            >
              &nbsp;{newMessageCount}&nbsp;
            </span>
          )}
        </div>
      </div>
      <div className="dropdown-menu" id="dropdown-menu2" role="menu">
        <div className="dropdown-content" style={{ width: '360px' }}>
          {oldNotifications &&
            oldNotifications.map((item: TNotification, index: number) => {
              if (index > 4) return;

              return (
                <div key={item._id.$oid}>
                  <a
                    href={item.link}
                    className="dropdown-item is-hoverable"
                    style={{ display: 'grid', gridTemplateColumns: '1fr 10fr', padding: '0rem 1rem 0rem 1rem' }}
                  >
                    {getIconForEntry(item.iconType)}
                    <div style={{ display: 'grid', gridTemplateRows: '1fr 1fr' }}>
                      <p className="has-text-weight-bold is-6">{item.text}</p>

                      <span className="has-text-weight-light is-size-7">{moment(item.createdAt.$date).fromNow()}</span>
                    </div>
                  </a>
                  <hr className="dropdown-divider" />
                </div>
              );
            })}
          <footer>
            <div className="has-text-centered">{error ? error : <Link to={NOTIFICATION_PATH}>View All</Link>}</div>
          </footer>
        </div>
      </div>
    </div>
  );
}
